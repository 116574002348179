import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  id: '',
  showModal: false,
  categoryModal: false,
  userDetailsModal: false,
  userShowDetailsModal: false,
  subscriptionShowDetailsModal: false,
  employeeStatusModal: false,
  employeeShowDetailsModal: false,
  partnerShowDetailsModal: false,
  partnerDetailsModal: false,
  eventDetailsModal: false,
  serviceDetailsModal: false,
  roomDetailsModal: false,
  bookingDetailsModal: false,
  taskDetailsModal: false,
  eventModal: false,
  assignMemberModal: false,
  forumDetailsModal: false,
  wellnessTypesModal: false,
  subscriptionStatusModal: false,
  additionalEmails: false,
  subServicesModal: false,
  plansModal: false,
  companyModal: false,
  classesModal: false,
  formDetails: {},
  eventDetail: {},
  partnerDetails: {},
  memberAssignedTrainerDetails:{},
  files: [],
  selectedPage: 1,
  partnerStatusModal: false,  
  sort:'date',
  subscribedAccountDetailsModal: false,
  startTime: '',
  endTime: ''
};

export const sharedSlice = createSlice({
  name: "sharedSlice",
  initialState: initialState,
  reducers: {
    setId: (state, { payload }) => {
      state.id = payload
    },
    setStartTime: (state, { payload }) => {
      state.startTime = payload
    },
    setEndTime: (state, { payload }) => {
      state.endTime = payload
    },
    setSort: (state, { payload }) => {
      state.sort = payload
    }, 
    setModalStatus: (state, { payload }) => {
      state.showModal = payload
    },
    setFormDetails: (state, { payload }) => {
      state.formDetails = payload
    },
    setEventDetail: (state, { payload }) => {
      state.eventDetail = payload
    },
    setCategoryModalStatus: (state, { payload }) => {
      state.categoryModal = payload
    },
    setUserModalStatus: (state, { payload }) => {
      state.userDetailsModal = payload
    },
    setUserShowModalStatus: (state, { payload }) => {
      state.userShowDetailsModal = payload
    },
    setPartnerShowModalStatus: (state, { payload }) => {
      state.partnerShowDetailsModal = payload
    },
    setPartnerModalStatus: (state, { payload }) => {
      state.partnerDetailsModal = payload
    },
    setEventModalStatus: (state, { payload }) => {
      state.eventDetailsModal = payload;
    },
    setServiceModalStatus:  (state, { payload }) => {
      state.serviceDetailsModal = payload;
    },
    setBookingModalStatus: (state, { payload }) => {
      state.bookingDetailsModal = payload;
    },
    setRoomModalStatus: (state, { payload }) => {
      state.roomDetailsModal = payload;
    },
    setTaskModalStatus: (state, { payload }) => {
      state.taskDetailsModal = payload;
    },
    setEventDetailModalStatus: (state, { payload }) => {
      state.eventModal = payload;
    },
    setForumModalStatus: (state, { payload }) => {
      state.forumDetailsModal = payload;
    },
    setFiles: (state, { payload }) => {
      state.files = payload.data
    },
    setPartnerProfileImages: (state, { payload }) => {
      state.formDetails.profilePicture = payload.data[0]?.url
    },
    setSelectedPage: (state, { payload }) => {
      state.selectedPage = payload
    },
    setAssignMembersModalStaus: (state, { payload }) => {
      state.assignMemberModal = payload
    },
    setMemberAssignedTrainerDetails:(state, { payload }) => {
      state.memberAssignedTrainerDetails = payload
    },
    setWellnessTypesModalStatus: (state, { payload }) => {
      state.wellnessTypesModal = payload;
    },
    setSubservicesModalStatus: (state, { payload }) => {
      state.subServicesModal = payload;
    },
    setCompanyModalStatus: (state, { payload }) => {
      state.companyModal = payload; 
    },
    setClassesModalStatus: (state, { payload }) => {
      state.classesModal = payload; 
    },
    setPartnerDetails: (state, { payload }) => {
      state.partnerDetails = payload
    },
    setSubscriptionDetails: (state, { payload }) => {
      state.subscriptionShowDetailsModal = payload
    },
    setEmployeeDetails: (state, { payload }) => {
      state.employeeShowDetailsModal = payload
    },
    setPartnerStatusModalStaus: (state, { payload }) => {
      state.partnerStatusModal = payload
    },
    setSubscriptionModalStatus: (state, { payload }) => {
      state.subscriptionStatusModal = payload
    },
    setEmployeeModalStatus: (state, { payload }) => {
      state.employeeStatusModal = payload
    },
    setPlansModalStatus: (state, { payload }) => {
      state.plansModal= payload
    },
    setSubscribedAccountDetails: (state, { payload }) => {
      state.subscribedAccountDetailsModal = payload
    },
    setAdditionalEmails: (state, { payload }) => {
      state.additionalEmails = payload;
    }
  },
});

export const {
  setId,
  setModalStatus,
  setFormDetails,
  setEventDetail,
  setCategoryModalStatus,
  setUserModalStatus,
  setEventModalStatus,
  setServiceModalStatus,
  setRoomModalStatus,
  setBookingModalStatus,
  setEventDetailModalStatus,
  setFiles,
  setPartnerProfileImages,
  setUserShowModalStatus,
  setPartnerShowModalStatus,
  setPartnerModalStatus,
  setTaskModalStatus,
  setForumModalStatus,
  setSelectedPage,
  setAssignMembersModalStaus,
  setMemberAssignedTrainerDetails,
  setWellnessTypesModalStatus,
  setSubservicesModalStatus,
  setCompanyModalStatus,
  setPartnerDetails,
  setPartnerStatusModalStaus,
  setSubscriptionModalStatus,
  setPlansModalStatus,
  setSubscriptionDetails,
  setEmployeeModalStatus,
  setEmployeeDetails,
  setSort,
  setSubscribedAccountDetails,
  setClassesModalStatus,
  setStartTime,
  setEndTime,
  setAdditionalEmails
} = sharedSlice.actions;