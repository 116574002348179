import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setAdditionalEmails, setCompanyModalStatus, setFormDetails } from "../../../../redux/features/shared/sharedSlice";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { Form } from "react-bootstrap";
import FieldInputText from "../common/InputFeilds/InputTextField";
import * as Yup from "yup";
import { REQUIRED } from "../../../../utils/const";
import { updateAddionalEmails } from "../../../../redux/features/company/_companyAction";

function AdditionalEmailModal() {
  const dispatch: any = useDispatch();
  const sharedActions: any = useSelector((state: any) => state.sharedActions);

  // Validation schema for the additional email domains
  const emailDomainValidation = Yup.object().shape({
    additionalEmailDomains: Yup.array()
      .of(
        Yup.string()
          .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid email domain format")
          .min(3, "Email domain must be at least 3 characters")
          .max(50, "Email domain must not exceed 50 characters")
          .required(REQUIRED)
      )
      .nullable(),
  });

  const formInitialValues = {
    additionalEmailDomains: sharedActions.formDetails.additionalEmailDomains || [],
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: emailDomainValidation,
    onSubmit: (values, { resetForm }) => {
      const cleanedValues = {
        ...values,
        additionalEmailDomains: values.additionalEmailDomains.filter(
          (domain: string) => domain.trim() !== ""
        ),
      };
      
      dispatch(updateAddionalEmails({ ...cleanedValues, id: sharedActions.formDetails?._id }));

      setTimeout(() => {
        dispatch(setAdditionalEmails(false));
        resetForm();
      }, 500);
    },
    enableReinitialize: true,
  });

  const closeModal = () => {
    dispatch(setAdditionalEmails(false));
    dispatch(setFormDetails({}));
  };

  return (
    <Modal backdrop="static" size="lg" show={sharedActions.additionalEmails} onHide={closeModal} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Add Additional Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} method="POST">
            <div className="row">
              <div className="col-sm-12 col-md-12 mb-6">
                <Form.Group>
                  <label>Additional Email Domains</label>
                  <FieldArray name="additionalEmailDomains">
                    {({ push, remove, form }) => (
                      <div>
                        {form.values.additionalEmailDomains.map((_, index) => {
                          const fieldName = `additionalEmailDomains[${index}]`;

                          return (
                            <div key={index} className="mb-2">
                              <div className="d-flex align-items-center">
                                <Field
                                  name={fieldName}
                                  type="text"
                                  component={FieldInputText}
                                  placeholder="Enter additional email domain"
                                  maxLength={50}
                                />
                                <Button
                                  variant="danger"
                                  type="button"
                                  onClick={() => remove(index)}
                                  className="ms-2"
                                >
                                  X
                                </Button>
                              </div>
                            </div>
                          );
                        })}

                        <Button
                          type="button"
                          variant="primary"
                          onClick={() => {
                            const lastIndex = form.values.additionalEmailDomains.length - 1;
                            if (
                              form.values.additionalEmailDomains.length === 0 ||
                              (form.values.additionalEmailDomains[lastIndex] && !form.errors.additionalEmailDomains?.[lastIndex])
                            ) {
                              push("");
                            }
                          }}
                        >
                          Add Another Domain
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </Form.Group>
              </div>
            </div>

            <Modal.Footer>
              <Button type="button" variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
              <Button type="submit" className="primaryBtn active">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
}

export { AdditionalEmailModal };
